<template>
  <div class="dp-pro-recipe-carousel-card">
    <img :src="content" alt="" @load="onImgLoaded" />
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: String,
      default: ''
    }
  },

  setup: (props, context) => {
    const cardContent = ref(null);

    const onImgLoaded = () => {
      context.emit('on-img-loaded');
    };

    return {
      cardContent,
      onImgLoaded
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-carousel-card {
  @include mixin.imageAspectRatio(343, 343);

  overflow: hidden;
}
</style>
